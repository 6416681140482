import React, { Component, useState, useEffect } from "react";
import Header from "../Common/Header";
import Footer from "../Common/Footer";
import $ from 'jquery'; 
import { Link } from "react-router-dom";


function Blog() {
  const [blogData, setblogData] = useState([]);
   const filterPra = { "NumberOfFieldsView": 50, "FromDt": "", "ToDt": "", "filters": {}, "isDownload": false, "index": 1, "pgSize": 100, "templateID": 3 }
   function fun_blogDataList() {
      fetch(
         "https://api.dialerp.com/api/api/getTemplateDataList",
         {

            method: "POST",
            headers: {
               "Authorization-Token":
                  "SOGjhYKNYtck4teM3O76aUKxHv8m58kd5OD/oDuEoL8U0Ln3o2IySf4aAvFvL9Av3CtbIgShGhcfw2CBB3900abZKy6nPcshKOdge5zqnyBiJ2NJsMFQ+Iti63mrxBduvvxOjlK+Lvykor08Gm+4wQ==",
               Accept: "application/json, text/plain",
               "Content-Type": "application/json;charset=UTF-8",
            },
            body: JSON.stringify(filterPra),

         }
      ).then((result) => {
         result.json().then((resp) => {
            setblogData(resp);
            console.log(resp, 'data')
         });
      });
   }
   useEffect(() => {
      fun_blogDataList();
   }, [0]);

  return (
    <>
    <div className="innerpage">
        <div><Header /></div>

       <div><div className="appie-page-title-area">
  <div className="container">
    <div className="row">
      <div className="col-lg-12">
        <div className="appie-page-title-item">
          <h3 className="title">Blogs</h3>
         
          {/* <div className="thumb">
              <img src="assets/images/signup-thumb.png" alt="" />
            </div> */}
        </div>
      </div>
    </div>
  </div>
</div>

 
       <section className="blogpage-section">
  <div className="container">
    <div className="row">
      <div className="col-lg-8 col-md-7">
        <div className="row">
        
          <div className="col-lg-6">

          {blogData?.response?.records?.map((blog, i) => (
            <div className="post-item-1" key={i}>
              <img src={blog[3].value} alt="" />
              <div className="b-post-details">
               
                <h3>
                  <Link to={`/${blog[0].value}`}>
                  {blog[4].value}
                  </Link>
                </h3>
                <Link className="read-more" to={`/${blog[0].value}`}>
                  Read More
                  <i className="fal fa-arrow-right" />
                </Link>
              </div>
            </div>
                  ))}
          </div>
        </div>
     
      </div>
    
    </div>
  </div>
</section>

       </div>
      
      <div>
        <Footer/>
      </div>
      </div>
    </>
  );
}

export default Blog;
