import React from "react";
import { Link } from "react-router-dom";

function Footer(){

    return(
        <div>
        {/*====== APPIE FOOTER PART START ======*/}
        <section className="appie-footer-area">
          <div className="container">
            <div className="row">
              <div className="col-lg-5 col-md-6">
                <div className="footer-about-widget">
                  <div className="logo">
                    <a href="#" className="sslogo"><img src="assets/images/logo.png" alt="" className="logo" /></a>
                  </div>
                  <p>In a B2B (business-to-business) jewellery application, buyers can experience several benefits, including:Wholesale Pricing: B2B buyers often access lower prices due to bulk purchasing, which can significantly increase their profit margins when they resell the items.</p>
                  <div className="social mt-30">
                    <ul>
                      <li><a href="#"><i className="fab fa-facebook-f" /></a></li>
                      <li><a href="#"><i className="fab fa-twitter" /></a></li>
                      <li><a href="#"><i className="fab fa-pinterest-p" /></a></li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div className="footer-navigation">
                  <h4 className="title">Useful Links</h4>
                  <ul>
                    <li><Link to="/">Home</Link></li>
                    <li><Link to="/about">About Us</Link></li>
                    <li><Link to="/retailer">Savya Buyer</Link></li>
                    <li><Link to="/seller">Savya Seller</Link></li>
                    <li><Link to="/contact">Contact</Link></li>
                    <li><Link to="">Privacy Policy</Link></li>
                    <li><Link to="">Terms & Conditions</Link></li>
                    <li><Link to="https://crmleads.erpthemes.com/" target="_blank">Admin Login</Link></li>
                  </ul>
                </div>
              </div>
             
              <div className="col-lg-3 col-md-6">
                <div className="footer-widget-info">
                  <h4 className="title">Get In Touch</h4>
                  <ul>
                    <li><a href="#"><i className="fal fa-envelope" />info@savyajewelsbusiness.com</a></li>
                    <li><a href="#"><i className="fal fa-phone" /> +91 11 4904 4145</a></li>
                    <li><a href="#"><i className="fal fa-phone" /> +91 11 6133 6155</a></li>
                    <li><a href="#"><i className="fal fa-map-marker-alt" /> Shop No 9, Building No 1165, Kucha Natwa, Kucha Mahajani, Chandni Chowk,New Delhi    
</a></li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="footer-copyright d-flex align-items-center justify-content-between pt-35">
                  
                  <div className="copyright-text text-center">
                    <p>Copyright © 2024 Savya Jewels. All rights reserved.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*====== APPIE FOOTER PART ENDS ======*/}
        {/*====== APPIE BACK TO TOP PART ENDS ======*/}
        <div className="back-to-top">
          <a href="#"><i className="fal fa-arrow-up" /></a>
        </div>
        {/*====== APPIE BACK TO TOP PART ENDS ======*/}
      </div>
    )
}

export default Footer