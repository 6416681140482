import React from "react";
import { Link } from "react-router-dom";

function Header() { 
  
  const navRef = React.useRef(null);
  const navRef2 = React.useRef(null);
  
  const onAddClick = (e) => {
    navRef.current.classList.add("active");
    navRef2.current.classList.add("active");
  };
  const onRemoveClick = (e) => {
    navRef.current.classList.remove("active");
    navRef2.current.classList.remove("active");
  };

  return (
    <div>
    {/*====== OFFCANVAS MENU PART START ======*/}
    <>
  <div className="off_canvars_overlay" ref={navRef2} onClick={onRemoveClick}></div>
  <div className="offcanvas_menu">
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <div className="offcanvas_menu_wrapper" ref={navRef}>
            <div className="canvas_close">
              <button onClick={onRemoveClick} className="tgl">
                <i className="fa fa-times" />
              </button>
            </div>
            <div className="offcanvas-brand text-center mb-40">
              <img src="assets/images/logo.png" alt="" />
            </div>
            <div id="menu" className="text-left ">
              <ul className="offcanvas_main_menu">
                
              <li><Link to="/">Home</Link> </li>
                <li><Link to="/about">About Us</Link></li>
                  <li>
                    <a href="#">Products <i class="fal fa-angle-down"></i></a>
                    <ul class="sub-menu">
                        <li><Link to="/seller">Savya Seller </Link></li>
                        <li><Link to="/retailer">Savya Buyer  </Link></li>
                    </ul>
                  </li>
                  <li>
                  <Link to="/blog">Blog</Link>
                    </li>
              
          
                  <li>
                  <Link to="/contact">Contact Us </Link>
                    </li>
              </ul>
            </div>
           
          </div>
        </div>
      </div>
    </div>
  </div>
</>

    {/*====== OFFCANVAS MENU PART ENDS ======*/}
    {/*====== PART START ======*/}
    <header className="appie-header-area appie-sticky">
      <div className="container">
      <div class="appie-btn-box text-right">
                          
                          <button class="toggle-btn ml-30 canvas_open d-lg-none d-block" onClick={onAddClick}>
                              <i class="fa fa-bars"></i>
                          </button>
                      </div>

                      

        <div className="header-nav-box">
          <div className="row align-items-center">
            <div className="col-lg-2 col-md-4 col-sm-5 col-6 order-1 order-sm-1">
              <div className="appie-logo-box">
                <Link to="/" className="sslogo">
                  <img src={process.env.PUBLIC_URL + 'assets/images/logo.png'} alt="" className="logo" />
                </Link>
              </div>
            </div>
            <div className="col-lg-10 col-md-1 col-sm-1 order-3 order-sm-2">
              <div className="appie-header-main-menu text-right" >
                <ul>
                <li><Link to="/">Home</Link> </li>
                <li><Link to="/about">About Us</Link></li>
                  <li>
                    <a href="#">Products <i class="fal fa-angle-down"></i></a>
                    <ul class="sub-menu">
                        <li><Link to="/seller">Savya Seller </Link></li>
                        <li><Link to="/retailer">Savya Buyer  </Link></li>
                    </ul>
                  </li>
                  <li>
                  <Link to="/blog">Blog</Link>
                    </li>
              
          
                  <li>
                  <Link to="/contact">Contact Us </Link>
                    </li>
                </ul>
             
              </div>
            </div>

       
               

          </div>
        </div>
      </div>
    </header>
  </div>
  );
}

export default Header;
