import React from "react";
import Header from "../Common/Header";
import Footer from "../Common/Footer";
import $ from 'jquery'; 


function About() {
  return (
    <>
    <div className="innerpage">
        <div><Header /></div>

       <div>
       <div className="appie-page-title-area">
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <div className="appie-page-title-item">
            <h3 className="title">About Us</h3>
         
           
          </div>
        </div>
      </div>
    </div>
  </div>
  {/*====== APPIE PAGE TITLE PART ENDS ======*/}



  {/*====== APPIE HOW IT WORK PART ENDS ======*/}
  <div className="appie-how-it-work-area pt-100 pb-90">
    <div className="container">
      <div className="row align-items-center">
        <div className="col-lg-6">
          <div className="how-it-work-thumb text-center">
            <img src="assets/images/how-it-work-thumb.png" alt="" />
          </div>
        </div>
        <div className="col-lg-6">
          <div className="appie-how-it-work-content">
            <h2 className="title">Welcome to Savya Jewels</h2>
            <p>Welcome to Savya Jewels—your trusted partner in the B2B jewellery market, designed to streamline and enhance the wholesale jewellery purchasing experience. We specialize in connecting jewellery sellers and buyers through a sophisticated digital platform that integrates the best of technology and industry expertise.
</p>


            <h5 style={{fontWeight:500, marginTop:30}}>Our Vision</h5>
            <p>At Savya Jewels, our vision is to innovate the traditional B2B jewellery marketplace. We aim to simplify the complex process of buying and selling jewellery at wholesale by providing a dual-application system that caters specifically to the needs of sellers and buyers within the industry.
</p>
           
          </div>
        </div>

        <div className="col-lg-12">

        <h3 style={{fontWeight:500, marginTop:30, marginBottom:30}}>Our Platforms</h3>
        
        <h5 style={{fontWeight:500, marginTop:30}}>Savya Jewels Seller Application</h5>

            <p>Our subscription-based Seller Application is crafted for jewellery manufacturers and wholesalers. It allows sellers to effortlessly manage their inventory, showcase their products to a wide audience, and gain insights through advanced analytics. Key features include inventory control, real-time sales tracking, and access to a growing network of buyers.</p>
           
            <h5 style={{fontWeight:500, marginTop:30}}>Savya Jewels Buyer Application</h5>

<p>Designed for retailers and bulk buyers, this subscription-based application offers a curated selection of jewellery pieces. With tools designed to facilitate easy navigation and secure transactions, buyers can leverage features like custom search filters, detailed product descriptions, and a secure checkout process to enhance their purchasing decisions.
</p>
<h5 style={{fontWeight:500, marginTop:30}}>Subscription Benefits</h5>

<p>
By subscribing to our services, both buyers and sellers benefit from exclusive features that are regularly updated to reflect the latest trends and technologies in the jewellery industry. Subscribers receive personalized support, regular updates, and access to special deals and promotions that are not available to non-subscribers.</p>

<h5 style={{fontWeight:500, marginTop:30}}>Our Commitment</h5>

<p>
Savya Jewels is committed to providing a secure, reliable, and transparent trading environment. We understand the intricacies of the jewellery market and strive to deliver solutions that not only meet but exceed</p>




          </div>
      </div>
    </div>
  </div>
  {/*====== APPIE HOW IT WORK PART ENDS ======*/}

       </div>
      
      <div>
        <Footer/>
      </div>
      </div>
    </>
  );
}

export default About;
